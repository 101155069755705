import { m, useInView } from "framer-motion";
import { useRef } from "react";

const Checkmark = () => (
    <svg xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Yes" width={20} height={20} viewBox="0 0 20 20" data-name="checkmark-filled-green" aria-hidden="true">
        <path d="M7.00002 14.2001L2.80002 10.0001L1.40002 11.4001L7.00002 17.0001L19 5.0001L17.6 3.6001L7.00002 14.2001Z" fill="#47B972" />
    </svg>
)

const Cross = () => (
    <svg xmlns="http://www.w3.org/2000/svg" role="img" aria-label="No" width={20} height={20} viewBox="0 0 20 20" data-name="cross-filled-red" aria-hidden="true">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.3639 2.22168L17.7782 3.63589L11.4138 9.99968L17.7782 16.3638L16.3639 17.778L9.9998 11.4137L3.63602 17.778L2.2218 16.3638L8.5858 9.99968L2.2218 3.63589L3.63602 2.22168L9.9998 8.58568L16.3639 2.22168Z" fill="#d0021b" />
    </svg>
)

const Comparison = () => {
    const conRef = useRef(null)
    const isInView = useInView(conRef)

    
    return <>
        <section id="comparison" className="my-[100px] max-w-[1600px]">  
            <div ref={conRef} className="flex flex-col">
                <div className="w-full flex items-center px-2">
                    <m.div
                        initial={{scale: 2, opacity: 0}}
                        animate={isInView ? {scale: 1, opacity: 1} : {}}
                        transition={{ duration: 0.6, type: "spring" }}
                    >
                        <h2 className="font-bold text-[4rem] max-xl:text-[3.2rem] max-lg:text-[3rem] max-md:text-[2rem]">CoinSafe vs. Traditional MFA Solutions</h2>
                        <h1 className="max-w-[800px]">A comparison of security, usability, and transparency between CoinSafe's blockchain-based MFA and traditional authentication methods like Google Authenticator and Authy.</h1>
                    </m.div>
                </div>
                <div className="mt-12 w-full">
                    <div className="bg-[#0000004d] px-[60px] max-md:px-2 py-6 overflow-x-auto">
                        <table className="w-full border-collapse table max-lg:text-sm max-md:text-[.7rem] max-[350px]:text-[.5rem]" >
                            <thead>
                                <tr>
                                    <th>Feature</th>
                                    <th>CoinSafe</th>
                                    <th>Authy</th>
                                    <th>Google Authenticator</th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr>
                                    <td>SIM-Swap Resistant</td>
                                    <td>
                                        <div className="flex max-md:flex-col gap-2 text-black-100"><Checkmark/> (No reliance on phone number)</div>
                                    </td>
                                    <td><Checkmark/></td>
                                    <td><Checkmark/></td>
                                </tr>
                                <tr>
                                    <td>Backup</td>
                                    <td>
                                        <div className="flex max-md:flex-col gap-2 text-black-100"><Checkmark/> (12-word seed phrase)</div>
                                    </td>
                                    <td>
                                        <div className="flex max-md:flex-col gap-2 text-black-100"><Checkmark/> (Phone backup)</div>
                                    </td>
                                    <td>
                                        <div className="flex max-md:flex-col gap-2 text-black-100"><Checkmark/> (Cloud backup)</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Multiple Device Support</td>
                                    <td><Checkmark/></td>
                                    <td><Checkmark/></td>
                                    <td><Checkmark/></td>
                                    
                                </tr>
                                <tr>
                                    <td>One-Tap Approvals</td>
                                    <td>
                                        <div className="flex max-md:flex-col gap-2 text-black-100"><Checkmark/></div>
                                    </td>
                                    <td>
                                        <div className="flex max-md:flex-col gap-2 text-black-100"><Checkmark/> (Uses codes as well)</div>
                                    </td>
                                    <td><Cross/></td>
                                </tr>
                                <tr>
                                    <td>Phishing Resistance</td>
                                    <td>
                                        <div className="flex max-md:flex-col gap-2 text-black-100"><Checkmark/></div>
                                    </td>
                                    <td><Cross/></td>
                                    <td><Cross/></td>
                                </tr>
                                <tr>
                                    <td>Decentralized Security</td>
                                    <td>
                                        <div className="flex max-md:flex-col gap-2 text-black-100"><Checkmark/></div>
                                    </td>
                                    <td><Cross/></td>
                                    <td><Cross/></td>
                                </tr>
                                <tr>
                                    <td>Blockchain Transparency</td>
                                    <td>
                                        <div className="flex max-md:flex-col gap-2 text-black-100"><Checkmark/></div>
                                    </td>
                                    <td><Cross/></td>
                                    <td><Cross/></td>

                                </tr>
                                <tr>
                                    <td>No Manual OTP Code Required</td>
                                    <td>
                                        <div className="flex max-md:flex-col gap-2 text-black-100"><Checkmark/> (No codes, one-tap approval)</div>
                                    </td>
                                    <td>
                                        <div className="flex max-md:flex-col gap-2 text-black-100"><Cross/></div>
                                    </td>
                                    <td>
                                        <div className="flex max-md:flex-col gap-2 text-black-100"><Cross/></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Comparison