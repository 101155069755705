import { useState } from "react"
import { m } from "framer-motion";


const menu = {
    open: {
        maxHeight: '100%',
        opacity: 1,
        borderRadius: '0px 0px 0% 0%',
        transition: {
            duration: .2,
            ease: "easeOut"
        }
    },
    closed: {
        maxHeight: '0px',
        opacity: .2,
        borderRadius: '0px 0px 100% 100%',
        transition: {
            duration: .1,
            ease: "easeOut"
        }
    }
}

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false)

    const handleItemClick = () => {
        setMenuOpen(false)
    }

    return <>
        <header className="w-full py-6 px-12 z-[9999] relative" >
            <m.div
                className="max-w-7xl mx-auto flex justify-between"
                initial={{y: '-100px', opacity: 0}}
                animate={{y: '0px', opacity: 1}}
                transition={{ duration: 0.8, type: "spring" }}
            >
                <a href="/">
                    <img className="max-h-[50px] max-md:max-h-[40px] max-sm:max-h-[30px] transition-all" src="./assets/Logo Asset 1.png" alt="coinSafe" />
                </a>

                <div className="flex items-center space-x-2 text-lg max-lg:text-[1rem] max-md:hidden">
                    <a href="#about" className="h-full flex items-center">
                        <span className="px-3 max-lg:px-1 duration-300 transition-colors hover:text-gold-300" >About</span>
                    </a>
                    <a href="#stepbystep" className="h-full flex items-center">
                        <span className="px-3 max-lg:px-1 duration-300 transition-colors hover:text-gold-300" >Step-by-Step</span>
                    </a>
                    <a href="#roadmap" className="h-full flex items-center">
                        <span className="px-3 max-lg:px-1 duration-300 transition-colors hover:text-gold-300" >Roadmap</span>
                    </a>
                    <div className="h-[20px] w-[4px] bg-gold-300"></div>
                    <a target="_blank" rel="noreferrer" href="https://docs.coinsafe.ai" className="h-full flex items-center">
                        <span className="px-3 max-lg:px-1 duration-300 transition-colors hover:text-gold-300" >Documentation</span>
                    </a>
                </div>
                <button onClick={() => setMenuOpen( oldM => !oldM )} className="flex items-center justify-center cursor-pointer md:hidden z-[999] text-gold-300" >
                    <svg className={`h-[32px] prepshow ${menuOpen ? 'hidden' : 'showSmooth'}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 18L20 18" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
                        <path d="M4 12L20 12" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
                        <path d="M4 6L20 6" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
                    </svg>
                    <svg className={`h-[32px] prepshow ${menuOpen ? 'showSmooth' : 'hidden'}`} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h48v48H0z" fill="none" />
                        <g id="Shopicon">
                            <g>
                                <polygon fill="currentColor" points="24,29.171 9.414,14.585 6.586,17.413 24,34.827 41.414,17.413 38.586,14.585" />
                            </g>
                        </g>
                    </svg>
                </button>
            </m.div>
        
        <div className={`fixed w-full left-0 top-0 h-screen md:hidden ${menuOpen ? '' : 'pointer-events-none'}`}>
            <m.div
                initial={'closed'}
                animate={menuOpen ? 'open' : 'closed'}
                variants={menu}
                className={`overflow-hidden text-right text-[1.8rem] backdrop-blur-sm h-full`}
            >
                <div className="flex flex-col pt-[100px] bg-black h-full px-10 space-y-3">
                    <a onClick={handleItemClick} href="#about">
                        <span className="px-3 duration-300 transition-colors hover:text-gold-300" >About</span>
                    </a>
                    <a onClick={handleItemClick} href="#stepbystep">
                        <span className="px-3 duration-300 transition-colors hover:text-gold-300" >Step-by-Step</span>
                    </a>
                    <a onClick={handleItemClick} href="#roadmap">
                        <span className="px-3 duration-300 transition-colors hover:text-gold-300" >Roadmap</span>
                    </a>
                    <a onClick={handleItemClick} target="_blank" rel="noreferrer" href="https://docs.coinsafe.ai">
                        <span className="px-3 duration-300 transition-colors hover:text-gold-300" >Documentation</span>
                    </a>
                </div>
            </m.div>
            
        </div>
        </header>
    </>
}

export default Header