
const Footer = () => {

    return <>
        <footer className="flex flex-col">
            <div className="bg-[#0a0a0a]">
                <div className="mx-6 lg:mx-32 my-7 flex justify-between flex-col lg:flex-row">
                    <div className="flex lg:block flex-col">
                        <img src="/assets/Logo Asset 1.png" className="w-[226px]" alt="logo" />
                        <p className="text-white text-base mt-4">Your Keys, Your Security, Your CoinSafe</p>
                        <p className="text-white text-xs">Secure. Simple. Decentralized.</p>
                    </div>
                    <div className="text-white flex max-sm:flex-col max-sm:flex-wrap w-fit gap-y-5 mt-8 lg:mt-0 pl-5 max-lg:pl-0 justify-between">
                        <div className="mr-4 lg:mr-16 flex flex-col">
                            <h3 className="text-white leading-5">About</h3>
                            <a className="text-gray-200 leading-4 mt-2 lg:mt-5" target="_blank" rel="noreferrer" href="https://docs.coinsafe.ai">Documentation</a>
                        </div>
                        <div className="mr-4 lg:mr-16 flex flex-col">
                            <h3 className="text-white leading-5">Contact</h3>
                            <div className="flex items-center space-x-1 mt-2 leading-4 lg:mt-4">
                                <svg className="text-gray-200 h-[22px] w-[22px]" version="1.1"xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xmlSpace="preserve">
                                    <g>
                                        <path fill="currentColor" d="M510.678,112.275c-2.308-11.626-7.463-22.265-14.662-31.054c-1.518-1.915-3.104-3.63-4.823-5.345
                                        c-12.755-12.818-30.657-20.814-50.214-20.814H71.021c-19.557,0-37.395,7.996-50.21,20.814c-1.715,1.715-3.301,3.43-4.823,5.345
                                        C8.785,90.009,3.63,100.649,1.386,112.275C0.464,116.762,0,121.399,0,126.087V385.92c0,9.968,2.114,19.55,5.884,28.203
                                        c3.497,8.26,8.653,15.734,14.926,22.001c1.59,1.586,3.169,3.044,4.892,4.494c12.286,10.175,28.145,16.32,45.319,16.32h369.958
                                        c17.18,0,33.108-6.145,45.323-16.384c1.718-1.386,3.305-2.844,4.891-4.43c6.27-6.267,11.425-13.741,14.994-22.001v-0.064
                                        c3.769-8.653,5.812-18.171,5.812-28.138V126.087C512,121.399,511.543,116.762,510.678,112.275z M46.509,101.571
                                        c6.345-6.338,14.866-10.175,24.512-10.175h369.958c9.646,0,18.242,3.837,24.512,10.175c1.122,1.129,2.179,2.387,3.112,3.637
                                        L274.696,274.203c-5.348,4.687-11.954,7.002-18.696,7.002c-6.674,0-13.276-2.315-18.695-7.002L43.472,105.136
                                        C44.33,103.886,45.387,102.7,46.509,101.571z M36.334,385.92V142.735L176.658,265.15L36.405,387.435
                                        C36.334,386.971,36.334,386.449,36.334,385.92z M440.979,420.597H71.021c-6.281,0-12.158-1.651-17.174-4.552l147.978-128.959
                                        l13.815,12.018c11.561,10.046,26.028,15.134,40.36,15.134c14.406,0,28.872-5.088,40.432-15.134l13.808-12.018l147.92,128.959
                                        C453.137,418.946,447.26,420.597,440.979,420.597z M475.666,385.92c0,0.529,0,1.051-0.068,1.515L335.346,265.221L475.666,142.8
                                        V385.92z" />
                                    </g>
                                </svg>
                                <a className="text-gray-200" href="mailto:ceo@coinsafe.ai">ceo@coinsafe.ai</a>
                            </div>
                        </div>
                        <div className="mr-4 lg:mr-16 flex flex-col">
                            <h3 className="text-white leading-5">Community</h3>

                            <a className="leading-4 mt-2 lg:mt-5 flex items-center space-x-2 text-gray-200" target="_blank" rel="noreferrer" href="https://x.com/coinsafeai">
                                <img className="h-4 w-4" src="/assets/x1.png" alt="x" />
                                <span>X</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-[#0a0a0a] flex justify-center border-t-[#111111] border-t-2">
                <p className="text-white my-7 mx-6 lg:mx-0">© 2024 CoinSafe. All Rights Reserved.</p>
            </div>
        </footer>
    </>
}


export default Footer